<template>
    <IdCardTemplate :type_id="type_id" :title="title" :type="type"/>
</template>
<script>
import IdCardTemplate from './index.vue'
export default {
	components:{IdCardTemplate},
	data:() => ({
		type_id:2,
		type:'ID_CARD',
		title:"ID Card Template"
	})

}
</script>

